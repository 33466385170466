import type { AppDispatch } from '@/store';

import { cabinHoldClearAction } from '@/ducks/cabinHold/actions';
import { bookNow } from '@/features/api/safeCalls/payment';
import { log } from '@/helpers/util/logger';

export const makeBookNow =
  (...args: unknown[]) =>
  async (dispatch: AppDispatch) => {
    try {
      const result = await bookNow(...args);
      dispatch(cabinHoldClearAction());
      return result;
    } catch (error) {
      log(error);
      console.error('Error BookNow:', error);
      throw error;
    }
  };
