import { ApolloError } from '@apollo/client';

import type { TReceived } from '@/infra/types/common';

import { apolloErrorToJson, extractQueryResultError } from '@/features/apollo/errorsToJson';
import getApolloClient from '@/features/apollo/getApolloClient';
import {
  getCabinCategoriesAvailabilityQueryDetails,
  type TCabinCategoriesAvailabilityRequest,
  type TCabinCategoriesAvailabilityResult,
  type TCabinCategory,
} from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';
import { type TAuthHeaders, TokenType, withAuthHeader } from '@/helpers/api/tokens/helpers/withToken';
import { log } from '@/helpers/util/logger';

type TQueryResponse = {
  cabinCategoriesAvailability: TCabinCategoriesAvailabilityResult[];
  cabinCategoriesAvailabilityV2?: TCabinCategoriesAvailabilityResult[];
};
export const fetchCabinCategories = (
  payload: TCabinCategoriesAvailabilityRequest,
  isFeatureFareClassesEnabled: boolean,
) =>
  withAuthHeader(
    TokenType.guest,
    async (authHeaders: TAuthHeaders): Promise<TReceived<TCabinCategory[] | undefined>> => {
      try {
        const queryDetails = getCabinCategoriesAvailabilityQueryDetails(isFeatureFareClassesEnabled);
        const result = await getApolloClient().query<TQueryResponse>({
          context: { headers: authHeaders },
          fetchPolicy: 'no-cache',
          query: queryDetails.query,
          variables: { value: payload },
        });
        const error = extractQueryResultError(result);
        if (error) {
          log(error);
          return {
            data: [],
            error,
          };
        }
        return {
          data: result.data[queryDetails.queryPath]?.[0]?.availableCategories || [],
        };
      } catch (exc) {
        const error = apolloErrorToJson(exc as ApolloError);
        log(error);
        return {
          data: [],
          error,
        };
      }
    },
  )();
