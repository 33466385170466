import type { AppDispatch, AppGetState } from '@/store';

import { ACCESS_KEY_PARAM } from '@/ducks/accessKeys/constants';
import { selectSettings } from '@/ducks/common/settings/selectors';
import { updateSearch } from '@/ducks/routes/history';
import { fetchAccessKeys, fetchFetchPromoCode } from '@/features/api/endpoints/accessKey';
import sharedCall from '@/helpers/sharedCall';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';

import {
  ACCESS_KEY_STATES,
  accessKeyDataIsReady,
  accessKeyDetailsError,
  accessKeyDetailsSuccess,
  accessKeyInited,
  accessKeyValidateError,
  accessKeyValidateSuccess,
  selectAccessKeySlice,
} from './accessKeys';
import decodeAKFromUrlParam from './decodeAKFromUrlParam';
import { mapAccessKeysErrorCodes } from './errorCodes';

const validateAccessKey = sharedCall(fetchFetchPromoCode);
const fetchAccessKeyDetailsData = sharedCall(fetchAccessKeys);

export const fetchAccessKeyDetailsAction =
  (accessKey: string, config = {}) =>
  async (dispatch: AppDispatch) => {
    try {
      const data = await fetchAccessKeyDetailsData(accessKey, config);
      dispatch(accessKeyDetailsSuccess(data));
    } catch (err) {
      dispatch(accessKeyDetailsError(err));
    }
  };

export const setAccessKeyErrorAction =
  ({ code, message }: { code?: number; message?: string }) =>
  (dispatch: AppDispatch) => {
    dispatch(accessKeyValidateError(code ? mapAccessKeysErrorCodes(code).defaultMessage : message));
  };

export const validateAccessKeyAction =
  (accessKey: string, config = {}) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const { accessKeyEnabled } = selectSettings(state);
    if (!accessKeyEnabled || !accessKey) {
      dispatch(accessKeyDataIsReady());
      return {};
    }

    const {
      accessKey: currentAccessKey,
      campaignKey,
      filterSailing,
      promoCode,
      state: status,
    } = selectAccessKeySlice(state) || {};
    const isAlreadyDone = accessKey === currentAccessKey && ACCESS_KEY_STATES.DETAIL === status;
    if (isAlreadyDone) return { accessKey, campaignKey, filterSailing, promoCode };

    try {
      dispatch(accessKeyInited(accessKey));

      const data = await validateAccessKey(accessKey, config);
      dispatch(accessKeyValidateSuccess({ ...data, source: 'URL' }));

      await dispatch(fetchAccessKeyDetailsAction(data.campaignKey, config));

      return data;
    } catch (err) {
      dispatch(setAccessKeyErrorAction({ code: err?.response ? err.response.status : null }));
      return { error: 'invalid access key' };
    }
  };

export const checkAccessKeyAndSwapToPromoCodeAction =
  (config = {}) =>
  async (dispatch: AppDispatch) => {
    const searchParams = getCurrentSearchParams();
    const data = await dispatch(checkAccessKeyAction(config));

    if (
      data.accessKey === decodeAKFromUrlParam(searchParams.get(ACCESS_KEY_PARAM)) &&
      data.accessKey !== data.promoCode
    ) {
      updateSearch({ ...searchParams, [ACCESS_KEY_PARAM]: data.promoCode });
    }

    return data;
  };

export const checkAccessKeyAction =
  (config = {}) =>
  async (dispatch: AppDispatch) => {
    const searchParams = getCurrentSearchParams();
    const accessKey = decodeAKFromUrlParam(searchParams.get(ACCESS_KEY_PARAM));
    if (!accessKey) {
      dispatch(accessKeyDataIsReady());
      return {};
    }
    return await dispatch(validateAccessKeyAction(accessKey, config));
  };

export const removeActiveAccessKeyErrorAction = () => (dispatch: AppDispatch) => {
  dispatch(accessKeyDetailsError(''));
};
