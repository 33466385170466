import type { TSailingData } from '@/infra/types/voyageInfo/sailing';

import { getSearchParams } from '@/ducks/routes/history';
import { getBookingSource } from '@/helpers/util/misc';

export function makeUnholdApiArgs(cabinNumber?: string, sailingData?: TSailingData) {
  const { debarkDate = '', embarkDate = '', voyageId = '' } = sailingData || {};
  const { currencyCode = 'USD', shipCode = '' } = getSearchParams();
  return {
    cabinDetails: [
      {
        cabinNumber,
        debarkDate: debarkDate,
        embarkDate: embarkDate,
      },
    ],
    shipCode: shipCode,
    voyageId: voyageId,
    ...getBookingSource(currencyCode, false, false),
  };
}
