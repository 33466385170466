import type { TFareClasses } from '@/features/fareClasses/types';
import type { TCabinCategorySubMeta } from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';

import { ADD_ONS_SEARCH_PARAM } from '@/ducks/addOns/constants';
import { pickAnyFromSearchParams, type PickAnyFromSearchParams } from '@/ducks/pages/navigation';
import { routes } from '@/ducks/routes';
import { ChooseCategorySearchParams } from '@/ducks/voyageInfo/category/searchParams';

export const onCategoriesChoosingCompleted = ({
  fareClass,
  isUpsellApplied,
  subCategoryCode,
  withAddons,
}: {
  fareClass?: TFareClasses;
  isUpsellApplied?: boolean;
  subCategoryCode: string;
  withAddons?: boolean;
}) => {
  const params = {
    keepSearch: true,

    [ChooseCategorySearchParams.chosenSubCategoryCode]: subCategoryCode,
    ...(isUpsellApplied !== undefined && { [ChooseCategorySearchParams.isUpsell]: isUpsellApplied }),
    ...(fareClass && { [ChooseCategorySearchParams.fareClass]: fareClass }),
  };

  routes.planner[withAddons ? 'addOns' : 'summary'].go(params);
};

export const onChooseAddons = ({ addOnsSerializedData }: { addOnsSerializedData?: string } = {}) => {
  routes.planner.summary.go({
    ...pickAnyFromSearchParams({
      withCelebration: true,
      withCharter: true,
      withChooseCabin: true,
      withChooseSailing: true,
      withFilters: true,
      withFM: true,
      withMNVV: true,
    }),
    ...(addOnsSerializedData && { [ADD_ONS_SEARCH_PARAM]: addOnsSerializedData }),
  });
};

export const backToMainPage = (args: PickAnyFromSearchParams = {}) => {
  routes.planner.chooseVoyage.go(
    pickAnyFromSearchParams({ withCharter: true, withFilters: true, withFM: true, withMNVV: true, ...args }),
  );
};

export const toPreCheckoutPage = (args: PickAnyFromSearchParams = {}) => {
  routes.planner.preCheckOut.go(
    pickAnyFromSearchParams({
      withCelebration: true,
      withCharter: true,
      withChooseSailing: true,
      withFilters: true,
      ...args,
    }),
  );
};

export const toSubCategoryPage = (
  chosenCategoryCode: TCabinCategorySubMeta['code'],
  args: PickAnyFromSearchParams = {},
) => {
  if (!chosenCategoryCode) {
    toCategoryPage();
    return;
  }

  routes.planner.chooseCabin.category.go(
    pickAnyFromSearchParams({
      withCelebration: true,
      withCharter: true,
      withChooseSailing: true,
      withFilters: true,
      ...args,
    }),
    '',
    { cabinCategoryCode: chosenCategoryCode },
  );
};

export const toCategoryPage = (args: PickAnyFromSearchParams = {}) => {
  routes.planner.chooseCabin.go(
    pickAnyFromSearchParams({
      withCelebration: true,
      withCharter: true,
      withChooseSailing: true,
      withFilters: true,
      ...args,
    }),
  );
};
