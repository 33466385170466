import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';

import {
  SUMMARY_CALCULATE_INVOICE_CLEAR,
  SUMMARY_CALCULATE_INVOICE_ERROR,
  SUMMARY_CALCULATE_INVOICE_START,
  SUMMARY_CALCULATE_INVOICE_SUCCESS,
} from '@/constants/actionTypes';
import { selectConfigServiceFeatureCelebrations } from '@/ducks/common/settings';
import { selectCurrencyCode, selectSailors } from '@/ducks/filters/selectors';
import { selectMgm } from '@/ducks/programs/mgm/selectors';
import { routes } from '@/ducks/routes';
import { clearChoseCategories } from '@/ducks/voyageInfo/category/actions';
import { toCategoryPage, toSubCategoryPage } from '@/ducks/voyageInfo/category/navigation';
import { calcInvoice as safeCalcInvoice } from '@/features/api/safeCalls/calcInvoice';
import { fetchUserProfile } from '@/features/profile/actions';
import { setWarningCabinCategoryExhausted } from '@/features/warnings/actions';
import { extractErrorMessage } from '@/helpers/extractErrorMessage';
import { getCabinInvoiceNumber } from '@/helpers/selectors';
import { getSessionStorageValue, setSessionStorageValue } from '@/helpers/util';
import { CalculateInvoiceFailureCode } from '@/infra/types/api/common';
import { cabinCategoryActions } from '@/reducers/voyagePlanner/chooseCabin/cabinCategories';
import { selectCabinByCode } from '@/reducers/voyagePlanner/chooseCabin/cabinCategories/selectors';

export const clearInvoiceDataAction = () => (dispatch) => {
  dispatch({ type: SUMMARY_CALCULATE_INVOICE_CLEAR });
};

export const wrapMessage = (message /* ?:string */) /* :({ message: string } | undefined) */ =>
  message ? { message } : undefined;

export const calculateInvoiceErrorAction = (err) => async (dispatch) => {
  dispatch({
    payload: isPlainObject(err) ? err : wrapMessage(extractErrorMessage(err)) || {},
    type: SUMMARY_CALCULATE_INVOICE_ERROR,
  });
};

export const fetchCalculateInvoiceDataAction = (params) => async (dispatch, getState) => {
  let data = {};
  const state = getState();

  dispatch(clearInvoiceDataAction());
  dispatch({
    type: SUMMARY_CALCULATE_INVOICE_START,
  });

  const requestParams = {
    ...params,
    currencyCode: selectCurrencyCode(state),
    sailors: selectSailors(state),
  };

  const promoCode = requestParams?.accessKey;

  const pathname = requestParams?.pathname;
  let externalRefId = requestParams?.externalRefId;
  if (!externalRefId) {
    const userDetails = localStorage.getItem('Authorization');
    if (!isEmpty(userDetails)) {
      const details = await dispatch(fetchUserProfile());
      externalRefId = details?.externalRefId;
    }
  }
  const isRouteFetch = [routes.planner.confirmation.path, routes.planner.payment.path].includes(pathname);
  const sessionInvoice = getSessionStorageValue('invoiceData');
  if (isRouteFetch && !isEmpty(sessionInvoice)) {
    data = sessionInvoice;
  } else if (!isEmpty(requestParams) && requestParams?.voyageId) {
    setSessionStorageValue('invoiceData', {});
    setSessionStorageValue('cabinNumber', '');
    data = await safeCalcInvoice({ ...requestParams, externalRefId, promoCode });
    if (data.isCaught) return;
  }

  const cabinTypeCode = params.chosenSubCategoryCode;
  if (data.isSellLimitExceeded || data.isSoldOut) {
    const categoryCode = selectCabinByCode(state, cabinTypeCode)?.code;
    dispatch(
      calculateInvoiceErrorAction({
        categoryCode,
        isSellLimitExceeded: data.isSellLimitExceeded,
        isSoldOut: data.isSoldOut,
        message: '',
        subCategoryCode: cabinTypeCode,
      }),
    );

    // If referred booker flow, have to stay on summary page and display modal window
    // instead of silent redirect

    if (selectConfigServiceFeatureCelebrations(state) && state.celebrations) {
      const { params } = state.celebrations;
      if (params.referralCode !== null) {
        return data;
      }
    }
    const { isReferredBooker } = selectMgm(state);
    if (isReferredBooker) {
      return data;
    }

    dispatch(clearChoseCategories());
    toSubCategoryPage(categoryCode);
    return;
  }

  if (data?.code === CalculateInvoiceFailureCode.SEAWEAR_SAILOR_WRONG_CAPACITY) {
    dispatch(setWarningCabinCategoryExhausted(true));
    dispatch(cabinCategoryActions.clearData());
    toCategoryPage();
    return;
  }

  dispatch({
    payload: data,
    type: SUMMARY_CALCULATE_INVOICE_SUCCESS,
  });
  setSessionStorageValue('invoiceData', data);
  const cabinNumber = getCabinInvoiceNumber(data);
  setSessionStorageValue('cabinNumber', cabinNumber);

  return data;
};

export default fetchCalculateInvoiceDataAction;
