import { createSelector } from '@reduxjs/toolkit';

import type { TCodifiedName } from '@/infra/types/common';
import type { RootState } from '@/store';

import createMemoSelector from '@/helpers/createMemoSelector';

const selectRoot = (state: RootState) => state?.voyagePlanner?.chooseCabin?.cabinCategories;

export const selectCabinCategories = (state: RootState) => selectRoot(state)?.data;

export const selectIsCabinCategoriesLoaded = (state: RootState) => selectRoot(state)?.isLoaded;

export const selectIsCabinCategoriesIsLoading = (state: RootState) => selectRoot(state)?.isLoading;

export const selectIsCabinCategoriesError = (state: RootState) => selectRoot(state)?.error;

export const selectCabinCategoriesApiPayload = (state: RootState) => selectRoot(state)?.apiPayload;

export const selectSeatMapVrIsLoaded = (state: RootState) => selectRoot(state)?.isSeatMapVrLoaded;

export const selectCabinCategory = createSelector(
  [selectCabinCategories, (_, categoryCode: string) => categoryCode],
  (cabinCategories, categoryCode) => {
    return cabinCategories?.find(({ code }) => code === categoryCode);
  },
);

export const selectCabinSubmeta = createSelector(
  [
    selectCabinCategories,
    (_, categoryCode: string) => categoryCode,
    (_, categoryCode: string, submetaIndex?: number) => submetaIndex,
  ],
  (cabinCategories, categoryCode, submetaIndex) => {
    if (submetaIndex == null) return;
    return cabinCategories?.find(({ code }) => code === categoryCode)?.submetas[submetaIndex];
  },
);

export const selectCabinByCode = createSelector(
  [selectCabinCategories, (_, cabinTypeCode?: string) => cabinTypeCode],
  (cabinCategories = [], cabinTypeCode) => {
    if (!cabinTypeCode) return;

    for (const { submetas } of cabinCategories) {
      for (const submeta of submetas) {
        const cabin = submeta.cabinTypes.find((cabinType) => cabinType.code === cabinTypeCode);
        if (cabin) {
          return cabin;
        }
      }
    }
  },
);

export const selectCabinNamesByCodesMap = createMemoSelector(selectCabinCategories, (data) => {
  if (!data || !data.length) return;

  const append = (map: Record<string, string>, item: TCodifiedName) => {
    if (item?.code && item?.name) {
      map[item.code] = item.name;
    }
  };
  return data?.reduce(
    (acc, category) => {
      category.submetas.forEach((submeta) => {
        submeta?.cabinTypes?.forEach((item) => append(acc, item));
        append(acc, submeta);
      });
      append(acc, category);
      return acc;
    },
    {} as Record<string, string>,
  );
});
