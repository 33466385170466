import { env } from '@/environment';
import { simpleFetchWithAuth, type TFetchOptions } from '@/features/api/simpleFetch';

export type TFetchPromoCodeResponse = {
  accessKey: string;
  campaignKey: string;
  filterSailing?: boolean;
  promoCode: string;
};

export const fetchFetchPromoCode = (accessKey: string, options?: TFetchOptions) =>
  simpleFetchWithAuth<TFetchPromoCodeResponse>(`${env.REST_BFF_URL}/fetchPromoCode/${accessKey}`, options);

// This endpoint returns http status 204 No Content
export const fetchAccessKeys = (campaignKey: string, options?: TFetchOptions) =>
  simpleFetchWithAuth<void>(`${env.REST_BFF_URL}/accesskeys/${campaignKey}`, options);
