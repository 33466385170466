import omit from 'lodash/omit';

import type { AppDispatch } from '@/store';

import { accessKeyClear } from '@/ducks/accessKeys/accessKeys';
import { ACCESS_KEY_PARAM } from '@/ducks/accessKeys/constants';
import { pickAnyFromSearchParams } from '@/ducks/pages/navigation';
import { removeParticularSearchParams, removeSearchParamsExcept } from '@/ducks/routes/history';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';
import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';

export const resetAKFlowFully = () => async (dispatch: AppDispatch) => {
  dispatch(accessKeyClear());
  removeParticularSearchParams([ACCESS_KEY_PARAM]);
};

export const resetAKFlowFullyAlongWithFilters = () => async (dispatch: AppDispatch) => {
  dispatch(accessKeyClear());
  removeParticularSearchParams(pickAnyFromSearchParams({ paramsToPick: [ACCESS_KEY_PARAM], withFilters: true }));
};

export const resetAKFlowSearchCriteria = () => (_dispatch: AppDispatch) => {
  removeSearchParamsExcept([ACCESS_KEY_PARAM]);
};

export const getSearchParamsObjectWithoutAccessKey = () =>
  omit(searchParamsAsObject(getCurrentSearchParams()), ACCESS_KEY_PARAM);
