import isEmpty from 'lodash/isEmpty';

import type { TCabinUnholdApiArgs } from '@/infra/types/cabin';
import type { RootState } from '@/store';
import type { TOptional } from '@/types';

import { selectCabinHold, selectCabinInvoices } from '@/ducks/pages/summary/selectors';
import { selectSailingData } from '@/ducks/voyageInfo/sailing/selectors';
import { buildCabinFullNumber } from '@/helpers/data/mappers/Cabin';
import { makeUnholdApiArgs } from '@/helpers/data/mappers/makeUnholdApiArgs';

export const selectCabinHoldIsHolded = (state: RootState) => selectCabinHold(state)?.state === 'holded';

const selectUnholdArgs = (state: RootState, isForced?: TOptional<boolean>): TOptional<TCabinUnholdApiArgs> => {
  const { data, state: holdState } = selectCabinHold(state) || {};
  if (holdState === 'unholding') return undefined;
  const { holdData, unholdArgs } = data || {};
  if (!isEmpty(unholdArgs)) return unholdArgs;

  const { cabinNumber } = holdData?.[0] || {};
  const [cabinInvoice] = selectCabinInvoices(state) || [];
  const sailingData = selectSailingData(state);
  if ((isForced || cabinNumber) && cabinInvoice) {
    const cabin = cabinNumber || buildCabinFullNumber(cabinInvoice);
    if (cabin && sailingData?.debarkDate && sailingData?.embarkDate && sailingData?.voyageId) {
      return makeUnholdApiArgs(cabin, sailingData) as TCabinUnholdApiArgs;
    }
  }
};

export default selectUnholdArgs;
