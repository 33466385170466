import get from 'lodash/get';

import { UPDATE_RESERVATION_ERROR_STATUS, UPDATE_RESERVATION_SUCCESS_STATUS } from '@/constants/actionTypes';
import { makeBookNow } from '@/ducks/payment/actions/makeBookNow';
import { matches, routes } from '@/ducks/routes';
import buildUpdateReservationBody from '@/helpers/api/mappers/req/bookNow/buildUpdateReservationBody';
import { getPaymentBooking, setPaymentBooking, setPaymentStatusCode } from '@/helpers/managers/payment';

import { fetchDashboardDataAction } from '../dashboard';

export function updateSailorDetailsAction(
  dashboardData,
  bookNowAdditionalArgs,
  isPromoCodeApplied,
  isUplift = false,
  isHold = false,
) {
  return async (dispatch) => {
    try {
      const data = await dispatch(makeBookNow(buildUpdateReservationBody(dashboardData, bookNowAdditionalArgs)));
      const reservationNumber = get(data, '[0].reservationNumber', '');
      if (!isPromoCodeApplied) {
        dispatch(fetchDashboardDataAction(reservationNumber));
      }
      if (isHold) {
        const booknowdata = getPaymentBooking();
        const dueDate = get(data, '[0].paymentDetails[0].depositAmount.dueDate', '');
        if (dueDate) {
          booknowdata.depositAmount.dueDate = dueDate;
          setPaymentBooking(booknowdata);
        }
      }
      if (isUplift) {
        setPaymentStatusCode(get(data, '[0].statusCode'));
        const booknowdata = getPaymentBooking();
        booknowdata.dueAmount = get(data, '[0].paymentDetails[0].dueAmount');
        setPaymentBooking(booknowdata);
      }

      dispatch({
        payload: { showBookNowErrorStatus: false },
        type: UPDATE_RESERVATION_SUCCESS_STATUS,
      });
      routes.searchWithout(['sailorId']);
    } catch (err) {
      if (!matches(routes.accounts.reservationDashboard.redirect)) {
        routes.page404.go();
      } else {
        const errorData = {
          showBookNowErrorStatus: true,
        };
        dispatch({
          payload: errorData,
          type: UPDATE_RESERVATION_ERROR_STATUS,
        });
      }
    }
  };
}
