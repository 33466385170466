import { calculateInvoiceErrorAction } from '@/actions';
import { throwError } from '@/ducks/common';
import { routes } from '@/ducks/routes';
import { getPathname } from '@/ducks/routes/history';
import { clearChoseCategories } from '@/ducks/voyageInfo/category/actions';
import { toCategoryPage, toPreCheckoutPage, toSubCategoryPage } from '@/ducks/voyageInfo/category/navigation';
import { selectChosenCategory } from '@/ducks/voyageInfo/category/selectors';
import { setWarningCabinCategoryExhausted, setWarningPreCheckoutCalcInvoiceFail } from '@/features/warnings/actions';
import { HttpError } from '@/helpers/api/utils';
import { CalculateInvoiceFailureCode } from '@/infra/types/api';
import { cabinCategoryActions } from '@/reducers/voyagePlanner/chooseCabin/cabinCategories';
import { rootStore } from '@/store';

type ErrorBody = {
  code?: string;
};

const calculateInvoiceFailCatcher = async (error: HttpError) => {
  const { status } = error?.response ?? ({} as Response);
  if (status < 400) return;

  const { code } = (error.responseBody || {}) as ErrorBody;

  const { dispatch, getState } = rootStore!;
  const state = getState();

  const canNavigate = [routes.planner.payment.path, routes.planner.summary.path].includes(getPathname());

  if (code === CalculateInvoiceFailureCode.SEAWEAR_SAILOR_NOT_PERMITTED) {
    if (canNavigate) {
      routes.planner.dnsError.go();
    }
    return;
  }
  if (code === CalculateInvoiceFailureCode.FORBIDDEN) {
    dispatch(setWarningPreCheckoutCalcInvoiceFail());
    if (canNavigate) {
      toPreCheckoutPage();
    }
    return;
  }

  if (
    code !== CalculateInvoiceFailureCode.SEAWEAR_SAILOR_WRONG_CAPACITY &&
    ([500, 502].includes(error.status) || code === 'INTERNAL_SERVER_ERROR')
  ) {
    dispatch(throwError(error));
    return;
  }

  dispatch(calculateInvoiceErrorAction(error));

  if (code === CalculateInvoiceFailureCode.SEAWEAR_SAILOR_WRONG_CAPACITY) {
    dispatch(setWarningCabinCategoryExhausted(true));
    dispatch(cabinCategoryActions.clearData());
    toCategoryPage();
    return;
  }

  if (canNavigate) {
    const chosenCategoryCode = selectChosenCategory(state)?.code;
    dispatch(clearChoseCategories());
    toSubCategoryPage(chosenCategoryCode);
  }

  return true;
};

export default calculateInvoiceFailCatcher;
